<template >
  <div style="background:red;height: 100%;">
    <div style="color: #000;height: 100%;" class="cbeijin">
      <!-- <van-nav-bar title="抽奖" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar> -->
      <!-- margin-top:46px;background: #f5f5f5; -->
      <div style="height: 25%;width: 100%;"></div>
      <div class="h1">
        <img class="cishu" src="../../assets/img/cishu.png" alt />
        <div class="jihui">
          您还有
          <span style="color:#FFFF00;font-size: 16px;">{{form.luckCount?form.luckCount:'0'}}</span> 次抽奖机会
        </div>
      </div>
      <div class="container-out">
        <!-- <div
        class="circle"
        v-for="(item,index) in circleList"
        :key="index"
        :style="{'top':(item.topCircle)/70+'rem','left':(item.leftCircle)/70+'rem','background-color':(index%2==0)?colorCircleFirst:colorCircleSecond}"
        ></div>-->
        <!-- top:(item.topAward)/100+'rem' -->
        <div class="container-in">
          <div
            class="content-out"
            v-for="(item,index) in awardList"
            :key="index"
            :style="{'background-color': (index==indexSelect)?colorAwardSelect:colorAwardDefault}"
          >
            <img class="award-image" :src="item.imageAward" />
          </div>
          <div
            class="start-btn"
            @click="startGame"
            :style="{'background-color':isRunning?'#e7930a':'#ffe400'}"
          >START</div>
        </div>
      </div>
      <div class="shuoming">奖品说明：</div>
      <div class="cdshuo" v-for="(item,idx) in shuoming" :key="idx">
        <span style="  font-weight: bold;" v-if="item.prizeName">{{item.prizeName}}：</span>
        <span v-if="item.comboNames">赠送套餐：{{item.comboNames}}{{item.prizeName?"、":""}}</span>
        <span v-if="item.balance">赠送储值金额：{{item.balance}}{{item.comboNames?"、":""}}</span>
        <span v-if="item.couponNames">赠送优惠券：{{item.couponNames}}{{item.balance?"、":""}}</span>
        <span v-if="item.score">赠送积分：{{item.score}}{{item.couponNames?"、":""}}</span>
        <span v-if="item.offlinePrize">{{item.offlinePrize}}</span>
      </div>

      <div style="height:48px"></div>
      <div class="bott">
        <div class="guizhe">活动规则</div>
        <div class="jiangping" @click="tojiangpin">我的奖品</div>
      </div>
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import { Dialog } from "vant";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: { [Dialog.Component.name]: Dialog.Component },
  data() {
    return {
      list: [],
      form: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      screenWidth: document.body.clientWidth, // 屏幕尺寸
      clientHeight: document.body.clientHeight, // 屏幕尺寸

      active: "0",
      //抽奖
      circleList: [], //圆点数组
      awardList: [], //奖品数组   真正渲染的列表  把每个奖品的偏移量也存放在一起
      colorCircleFirst: "#FFDF2F", //圆点颜色1
      colorCircleSecond: "#FE4D32", //圆点颜色2
      colorAwardDefault: "#d2bcb1", //奖品默认颜色
      colorAwardSelect: "#fff0e3", //奖品选中颜色
      indexSelect: 0, //被选中的奖品index
      isRunning: false, //是否正在抽奖
      imageAward2: [],
      imageAward: [], //奖品图片数组
      shuoming: []
    };
  },
  methods: {
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        // gid: 1,
        Status: 1
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .choujianglist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
          var listdata = e.data;
          var isok = false;
          var dist = {};
          for (var i = 0; i < 4; i++) {
            this.shuoming.push(dist);
          }

          this.list.forEach((item, index) => {
            if (item.prizeName == "无奖") {
              isok = true;
            }
            if (item.prizeName == "一等奖") {
              var lis = {
                prizeName: item.prizeName,
                comboNames: item.comboNames,
                balance: item.balance,
                couponNames: item.couponNames,
                score: item.score,
                offlinePrize: item.offlinePrize
              };
              this.shuoming[0] = lis;
              // this.shuoming.push(lis);
            } else if (item.prizeName == "二等奖") {
              var lis = {
                prizeName: item.prizeName,
                comboNames: item.comboNames,
                balance: item.balance,
                couponNames: item.couponNames,
                score: item.score,
                offlinePrize: item.offlinePrize
              };
              this.shuoming[1] = lis;
            } else if (item.prizeName == "三等奖") {
              var lis = {
                prizeName: item.prizeName,
                comboNames: item.comboNames,
                balance: item.balance,
                couponNames: item.couponNames,
                score: item.score,
                offlinePrize: item.offlinePrize
              };
              this.shuoming[2] = lis;
            } else if (item.prizeName == "四等奖") {
              var lis = {
                prizeName: item.prizeName,
                comboNames: item.comboNames,
                balance: item.balance,
                couponNames: item.couponNames,
                score: item.score,
                offlinePrize: item.offlinePrize
              };
              this.shuoming[3] = lis;
            }
          });

          if (isok) {
            for (var i = 0; i < 8; i++) {
              var data = {
                name: "谢谢参与",
                id: 0,
                img: require("../../assets/img/wu.png")
              };
              this.imageAward.push(data);
            }
            if (e.data.length == 2) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              this.imageAward[0] = listdata[0];
            } else if (e.data.length == 3) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              this.imageAward[0] = listdata[0];
              this.imageAward[4] = listdata[1];
            } else if (e.data.length == 4) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              this.imageAward[1] = listdata[0];
              this.imageAward[4] = listdata[1];
              this.imageAward[6] = listdata[2];
            } else if (e.data.length == 5) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              this.imageAward[0] = listdata[0];
              this.imageAward[2] = listdata[1];
              this.imageAward[4] = listdata[2];
              this.imageAward[6] = listdata[3];
            }
          } else {
            //无免费券的时候
            console.log("无免费券的时候");
            var form = {};
            for (var i = 0; i < 8; i++) {
              var data = {
                name: "谢谢参与",
                id: 0,
                img: require("../../assets/img/wu.png")
              };
              this.imageAward.push(data);
            }
            if (e.data.length == 1) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
                form = item;
              });

              for (var i = 0; i < 8; i++) {
                this.imageAward.push(form);
              }
            } else if (e.data.length == 2) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              this.imageAward[1] = listdata[0];
              for (var i = 0; i < 7; i++) {
                this.imageAward.push(listdata[1]);
              }
            } else if (e.data.length == 3) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              form = listdata[2];
              this.imageAward[0] = form;
              this.imageAward[1] = listdata[0];
              this.imageAward[2] = form;
              this.imageAward[3] = form;
              this.imageAward[4] = listdata[1];
              this.imageAward[5] = form;
              this.imageAward[6] = listdata[2];
              this.imageAward[7] = form;
            } else if (e.data.length == 4) {
              listdata.forEach((item, index) => {
                item.name = item.prizeName;
              });
              form = listdata[3];
              this.imageAward[0] = listdata[0];
              this.imageAward[1] = form;
              this.imageAward[2] = listdata[1];
              this.imageAward[3] = form;
              this.imageAward[4] = listdata[2];
              this.imageAward[5] = form;
              this.imageAward[6] = listdata[3];
              this.imageAward[7] = form;
            }
          }

          console.log(this.imageAward);
          this.draw();
        })
        .catch(() => loading.clear());
    },

    //画页面的原点以及每个奖品图片的位置
    draw() {
      var _this = this;
      //圆点设置的每一个位置的偏移量  总共24个原点
      var leftCircle = 7.5;
      var topCircle = 7.5;
      var circleList = [];
      for (var i = 0; i < 24; i++) {
        if (i == 0) {
          topCircle = 15;
          leftCircle = 15;
        } else if (i < 6) {
          topCircle = 7.5;
          leftCircle = leftCircle + 102.5;
        } else if (i == 6) {
          topCircle = 15;
          leftCircle = 620;
        } else if (i < 12) {
          topCircle = topCircle + 94;
          leftCircle = 620;
        } else if (i == 12) {
          topCircle = 565;
          leftCircle = 620;
        } else if (i < 18) {
          topCircle = 570;
          leftCircle = leftCircle - 102.5;
        } else if (i == 18) {
          topCircle = 565;
          leftCircle = 15;
        } else if (i < 24) {
          topCircle = topCircle - 94;
          leftCircle = 7.5;
        } else {
          return;
        }
        circleList.push({ topCircle: topCircle, leftCircle: leftCircle });
      }

      _this.circleList = circleList; //原点

      //设置圆点闪烁
      setInterval(function() {
        if (_this.colorCircleFirst == "#FFDF2F") {
          _this.colorCircleFirst = "#FE4D32";
          _this.colorCircleSecond = "#FFDF2F";
        } else {
          _this.colorCircleFirst = "#FFDF2F";
          _this.colorCircleSecond = "#FE4D32";
        }
      }, 500); //设置圆点闪烁的效果

      //奖品item设置
      var awardList = [];

      //间距,怎么顺眼怎么设置吧.
      var topAward = 25;
      var leftAward = 25;
      for (var j = 0; j < 8; j++) {
        if (j == 0) {
          topAward = 25;
          leftAward = 25;
        } else if (j < 3) {
          topAward = topAward;
          //166.6666是宽.15是间距.下同
          leftAward = leftAward + 166.6666 + 15;
        } else if (j < 5) {
          leftAward = leftAward;
          //150是高,15是间距,下同
          topAward = topAward + 150 + 15;
        } else if (j < 7) {
          leftAward = leftAward - 166.6666 - 15;
          topAward = topAward;
        } else if (j < 8) {
          leftAward = leftAward;
          topAward = topAward - 150 - 15;
        }

        var imageAward = _this.imageAward[j];
        awardList.push({
          topAward: topAward,
          leftAward: leftAward,
          name: imageAward.name,
          imageAward: imageAward.img
        });
      }

      _this.awardList = awardList;
    },

    //开始抽奖
    startGame: function() {
      if (this.isRunning) return;
      if (this.form.luckCount <= 0) {
        this.$toast("抽奖次数不足");
        return;
      }
      if (this.chexin.carNo == "" || this.chexin.carNo == null) {
        this.$toast("请先设置车牌！");
        return;
      }
      var jiangID = "";
      const data = {
        gid: this.shangjia.id,
        carNo: this.chexin.carNo ? this.chexin.carNo : "",
        customerId: this.userInfo.customerId ? this.userInfo.customerId : ""
        // gid: 1,
        // carNo: "闽YYYTFF",
        // customerId: 205
      };
      orderModel
        .choujiangdraw(data)
        .then(e => {
          jiangID = e.data;
          this.cishu();
        })
        .catch(() => loading.clear());
      this.isRunning = true; //静止点击
      var _this = this;
      var indexSelect = 0; //默认从0开始
      var i = 0; //控制速度的

      var id = 6;
      var timer = setInterval(function() {
        indexSelect++;
        //这里我只是简单粗暴用y=30*x+200函数做的处理.可根据自己的需求改变转盘速度
        i += 40;

        indexSelect = indexSelect % 8;
        //  console.log(indexSelect)
        _this.indexSelect = indexSelect;
        console.log(_this.imageAward[indexSelect]);
        //固定下标停止,当满足转动时间并且奖品下标是3的时候停止定时器
        if (i > 1000 && _this.imageAward[indexSelect].id == jiangID) {
          //去除循环
          clearInterval(timer);
          var title = "";
          var message = "";

          if (_this.imageAward[indexSelect].name == "谢谢参与") {
            title = "很遗憾";
            message = _this.awardList[indexSelect].name;
          } else {
            title = "恭喜";
            message = "获得了" + _this.awardList[indexSelect].name;
          }
          Dialog.alert({
            title: title,
            message: message
            //当点击了弹框确定之后  重置开始按钮状态并且重置获奖下标从0开始
          }).then(() => {
            _this.isRunning = false;
            // _this.indexSelect = 0;
          });
        }
      }, 200 + i);
    },
    tojiangpin() {
      this.$router.push({
        name: "Lucky_mine"
      });
    },
    cishu() {
      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo.id
      };
      orderModel
        .count(data)
        .then(e => {
          // loading.clear();
          this.form = e.data;
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },

  created() {
    this.cishu();
    this.onLoad();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        window.clientHeight = document.body.clientHeight;
        that.screenWidth = window.screenWidth;
        that.clientHeight = window.clientHeight;
        console.log(that.screenWidth);
        console.log(that.clientHeight);
      })();
    };
  }
};
</script>

<style lang="less" scoped>
.cbeijin {
  background: url(../../assets/img/choujiang2.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.guizhe {
  float: left;
  font-size: 18px;
  margin-left: 11%;
  line-height: 44px;
  padding: 0 28px;
  opacity: 0;
}
.shuoming {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  padding-left: 18px;
  margin-top: 20px;
}
.cdshuo {
  font-size: 14px;
  color: #fff;
  line-height: 18px;
  padding-left: 48px;
  padding-right: 12px;
}
.jiangping {
  float: left;
  font-size: 18px;
  margin-left: 10%;
  line-height: 44px;
  padding: 0 28px;
  //   left: 50%;
  opacity: 0;
}
.bott {
  background: url(../../assets/img/bott.png) no-repeat;
  background-size: 100% 100%;
  position: fixed;
  width: 100%;
  height: 44px;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
}
.cishu {
  height: 45px;
}
.h1 {
  height: 45px;
  // background-size: 100% 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: relative;
}
.jihui {
  width: 200px;
  position: absolute;
  top: 0;
  left: 50%;
  line-height: 30px;
  margin-left: -100px;
}
.container-out {
  background: url(../../assets/img/choutu.png) no-repeat;
  background-size: 100% 100%;
  // height: 46%;
  // width: 100%;
  height: 300px;
  // left: 50%;
  // margin-left: -44%;
  //   background-color: #b136b9;
  width: 340px;
  margin: auto;
  border-radius: 0.4rem;
  //   box-shadow: 0 0.1px 0 #871a8e;
  position: relative;
  //   top: 34%;
  // margin-top: 5%;
}

.container-in {
  width: 100%;
  height: 90%;
  margin-left: -45%;
  //   background-color: #871a8e;
  border-radius: 0.4rem;
  position: absolute;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 20px;
  // margin: auto;
}

/**小圆球
box-shadow: inset 3px 3px 3px #fff2af;*/
.circle {
  position: absolute;
  display: block;
  border-radius: 50%;
  height: 0.2rem;
  width: 0.2rem;
}

.content-out {
  position: absolute;
  height: 80px;
  width: 90px;
  background-color: #f5f0fc;
  border-radius: 0.15rem;
  //   box-shadow: 0 0.05px 0;
}
.content-out:nth-child(1) {
  margin-left: 8px;
}
.content-out:nth-child(2) {
  margin-left: 108px;
}
.content-out:nth-child(3) {
  margin-left: 208px;
}
.content-out:nth-child(4) {
  margin-left: 208px;
  margin-top: 90px;
}
.content-out:nth-child(5) {
  margin-left: 208px;
  margin-top: 180px;
}
.content-out:nth-child(6) {
  margin-left: 108px;
  margin-top: 180px;
}
.content-out:nth-child(7) {
  margin-left: 8px;
  margin-top: 180px;
}
.content-out:nth-child(8) {
  margin-left: 8px;
  margin-top: 90px;
}
/**居中 加粗*/

.start-btn {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30%;
  width: 31%;
  background-color: #ffe400;
  box-shadow: 0 0.05px 0 #e7930a;
  color: #f6251e;
  text-align: center;
  font-size: 0.4rem;
  font-weight: bolder;
  line-height: 1.5rem;
  opacity: 0;
}

.award-image {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1.4rem;
  width: 1.3rem;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.ddccd {
  color: rgb(1, 177, 133) !important;
}
</style>